<template>
  <div>
    <el-row :gutter="20" class="search-box">
      <el-col :span="10">
        <eb-date-range v-model="dateRange" @change="changeDate"></eb-date-range>
      </el-col>

      <el-col :span="4">
        <el-button type="primary" @click="getList(1)">查询</el-button>
      </el-col>
    </el-row>

    <el-table
      border
      :data="dataSource"
      class="page-top-space"
      row-class-name="table-row"
    >
      <el-table-column type="selection"></el-table-column>
      <el-table-column label="首次购买用户" prop="firstPayNums"></el-table-column>
      <el-table-column label="续费用户" prop="repeatPayNums" align="right"></el-table-column>
      <el-table-column label="2次购买" prop="twoPayNums"></el-table-column>
      <el-table-column label="退网用户" prop="noPayNums"></el-table-column>
      <el-table-column label="总购买次数" prop="paynum"></el-table-column>
      <el-table-column label="总金额" prop="paytotal">
        <template #default="scope">
          <span>{{ util.formatNum(scope.row.paytotal / 100) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="平均套餐金额" prop="averagePay" align="right">
        <template #default="scope">
          <span>{{ util.formatNum(scope.row.averagePay / 100) }}</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { fetchUserOrderStatistics } from "@/api/batteryMan";
import { mapState } from "vuex";
import moment from "moment";

export default {
  computed: mapState({
    agentList: (state) => {
      return state.agent.agentList || { list: [], map: {} };
    },
  }),
  data() {
    let startTime = moment().subtract(1, "month").format("YYYY-MM-DD HH:mm:ss");
    let endTime = moment().endOf("d").format("YYYY-MM-DD HH:mm:ss");

    let userId = this.$router.currentRoute._value.query.id;
    return {
      count: {
        money: 0,
        len: 0,
        avg: 0,
      },
      dateRange: [startTime, endTime],
      dataSource: [],
      modalData: {},
      query: {
        startTime: startTime,
        endTime: endTime,
        userId: userId ? userId : -1,
      },
      modalType: "add",
      MODAL_KEY: {
        EDIT_MODAL: "EDIT_MODAL",
      },
      total: 0,
    };
  },
  mounted() {
    this.getList();
  },

  methods: {
    changeDate(e) {
      if (!this.validatenull(e)) {
        this.query.startTime = moment(e[0])
          .startOf("d")
          .format("YYYY-MM-DD HH:mm:ss");
        this.query.endTime = moment(e[1])
          .endOf("d")
          .format("YYYY-MM-DD HH:mm:ss");
      }
    },

    resetPage() {
      this.query = {
        startTime: "2021-08-10 15:50:26",
        endTime: "2022-09-23 15:50:26",
        userId: -1,
      };
    },

    changeArea(e) {
      console.log(e);
    },
    doDetail(id) {
      this.$router.push("/user/detail/" + id);
    },

    getList() {
      fetchUserOrderStatistics({
        ...this.query,
      }).then((res) => {
        let list = [res.data.data.records] || [];
        this.dataSource = list;
      });
    },
  },
};
</script>
